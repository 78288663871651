// primary
$clr-pri-med: #ed7625;
$clr-pri-light: #ff9a55;
$clr-pri-lightest: #f5e8e1;
// secondary
$clr-sec-dark: #005878;
$clr-sec-light: #208fb4;

// grays
$clr-gray-dark: #292b2d;
$clr-gray-light: #bababa;
$clr-gray-lighter: #ededef;
$clr-gray-lightest: #fff;
// accessibility
$clr-acc-error: #ed1c24;

* {
  box-sizing: border-box;
}
.hidden {
  display: none !important;
}
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  background: $clr-gray-lighter;
  font-size: 16px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-stretch: ultra-condensed;
  font-weight: 400;
  font-display: optional;
  color: $clr-gray-dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


