@import "index.scss";

#loader {
  left: 50%;
  top: 50%;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#spinner {
  box-sizing: border-box;
  stroke: $clr-pri-light;
  stroke-width: 3px;
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: line 1.6s cubic-bezier(0.4, 0, 0.2, 1) infinite,
    rotate 1.6s linear infinite;
  animation: line 1.6s cubic-bezier(0.4, 0, 0.2, 1) infinite,
    rotate 1.6s linear infinite;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@-webkit-keyframes line {
  0% {
    stroke-dasharray: 2, 85.964;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    stroke-dasharray: 65.973, 21.9911;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 2, 85.964;
    stroke-dashoffset: -65.973;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes line {
  0% {
    stroke-dasharray: 2, 85.964;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    stroke-dasharray: 65.973, 21.9911;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 2, 85.964;
    stroke-dashoffset: -65.973;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.header {
  width: 100%;
  height: 66px;
  color: $clr-gray-lightest;
  background: $clr-gray-dark;
  position: fixed;
  font-size: 20px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 9px 1px rgba(0, 0, 0, 0.11),
    0 4px 2px -2px rgba(0, 0, 0, 0.1);
  padding: 16px 16px 0 16px;
  z-index: 1000;
  text-align: right;
}
.header__logo {
  display: inline-block;
  width: auto;
  max-height: 37px;
  max-width: 49%;
}
.header__logo:first-of-type {
  float: left;
}

#main {
  background: $clr-gray-lightest;
  padding: 80px 10px 10px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}


.body {
  max-width: 30em;
}

.title {
  margin-top: 0;
  font-size: 1.5em;
  color: $clr-gray-dark;
}
.calc {
  margin: 0 0 20px;
}
.calc__label {
  display: block;
  font-size: 1em;
}
.calc__select,
.calc__input {
  display: block;
  margin: 5px 0;
  padding: 0 0 0 10px;
  width: 100%;
  min-height: 48px;
  background: $clr-gray-lightest;
  border: 2px solid $clr-gray-light;
  border-radius: 3px;
  font-size: 1.25em;

  &:focus,
  &:active {
    outline: none;
    border-color: $clr-gray-lighter;
  }
}
.calc__input.readonly {
  border-color: $clr-gray-lightest;
}
select,
.calc__select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  background: $clr-gray-lightest url("../img/caret-circle-down.svg")
    no-repeat 97% center;
  background-size: 22px 22px;
  padding-right: 10%;
}
select::-ms-expand {
  display: none;
}
.calc__input.invalid {
  border-color: $clr-acc-error;
}
.calc__helper {
  display: block;
  margin: 0;
  font-size: 0.7em;
}
.calc__helper.error {
  float: right;
}
.error {
  color: $clr-acc-error;
}

#results {
  background: $clr-gray-lightest;
}
.card {
  margin: 0 0 10px;
  overflow: hidden;
  border: 2px solid $clr-gray-light;
  border-radius: 3px;

  h3 {
    margin: 0;
    padding: 4px 8px;
    font-size: 1em;
  }
  p {
    margin: 0;
    padding: 4px 8px;
    font-size: 0.85em;
    border-top: 1px solid $clr-gray-lighter;
  }
}
.card__title {
  display: block;
  margin: 0;
  padding: 8px;
  background: $clr-gray-light;
  font-size: 1em;
  font-weight: bold;
}
.card--pri .card__title {
  background: $clr-pri-med;
  color: $clr-gray-lightest;
}
.card__number {
  float: right;
}
#installBanner,
#update {
  background: $clr-pri-lightest;
}
.section {
  margin: 0;
  padding: 1px 10px;
}
h4 {
  margin: 12px 0 0;
}
#main .section {
  margin: 10px -10px;
}
button {
  margin: 10px 0;
  padding: 4px 8px;
  background: $clr-gray-lightest;
  border: 2px solid $clr-gray-light;
  border-radius: 3px;
  font-size: 1.125em;
}
#submit {
  border-color: $clr-pri-med;
  background: $clr-pri-med;
  color: $clr-gray-lightest;
}
.btn-right {
  float: right;
}
span.settings-on,
span.settings-off {
  float: right;
  display: block;
  opacity: 0.5;
  width: 25px;
  height: 25px;
  text-decoration: none;
  font: normal 1em/1em sans-serif;
  color: #ccc;
  background: $clr-gray-lightest no-repeat center center;
  background-size: 22px 22px;

  span {
    visibility: hidden;
  }

  &:hover {
    cursor: pointer;
  }
}
#btnSettingsOpen {
  background-image: url("../img/cog.svg");
}
#btnSettingsClose {
  background-image: url("../img/clear-button.svg");
}
.calc__helper.settings-on {
  display: block;
  float: right;
  padding: 0 15px 0 8px;
  background: $clr-gray-lightest url("../img/cog.svg") no-repeat center
    right;
  background-size: 12px 12px;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

#btnInstall {
  margin-top: 0;
}
.logo-amaferm {
  display: block;
  width: 135px;
  margin: 10px auto;
}
.pull {
  margin: 10px 0 0;
  font-weight: 700;
  font-size: larger;
}
.pull-ul {
  margin: 0 0 20px;
  padding: 8px;
  background: $clr-gray-lighter;

  ul {
    margin: 0 0 0 24px;
    padding: 0;

    li {
      padding: 5px 0;
      font-size: larger;
    }
  }
}

footer {
  margin: 0;
  padding: 10px;
  background: $clr-gray-lighter;

  p {
    font-size: 0.75em;
  }
  a {
    color: $clr-sec-dark;
  }
}
.footer-quote {
  max-width: 100%;
  height: 37px;
}

@media only screen and (min-width: 599px) {
  .logo-amaferm {
    float: left;
    width: 101px;
    margin: 0 10px 0 0;
  }
}
@media print {
  button,
  .banner,
  footer .links {
    display: none !important;
  }
  .header {
    position: relative;
  }
  #main {
    padding-top: 15px;
  }
  .body {
    clear: both;
    max-width: none;
  }
}
